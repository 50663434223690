<template>
  <section class="side-categoreis">
    <div class="side-continer">
      <BaseSideBar>
        <transition-group tag="div" name="menu-open-mobile">
          <BaseSearch
            v-if="
              ($store.getters.isMobile || $store.getters.isTablet) &&
              $route.name == 'cart' &&
              needToCloseSideBar
                ? false
                : true
            "
          ></BaseSearch>
        </transition-group>
      </BaseSideBar>
      <BaseButton @click="navigateToCategoryPage">
        <div>
          <div class="flex gap-20">
            <img class="plus-icon" src="/images/plusSmall.png" />
            <span>{{ $t("home.newOrder") }}</span>
          </div>
        </div>
      </BaseButton>
      <h5>קטגוריות מוצרים</h5>
      <Categories />
    </div>
  </section>
</template>

<script>
import BaseSideBar from "../menus/BaseSideBar.vue";
import BaseSearch from "../menus/BaseSearch.vue";
import BaseButton from "../helpers/BaseButton.vue";
import Categories from "../menus/Categories.vue";
import { mapGetters } from "vuex";

import { routerNames } from "@/router";
export default {
  components: {
    BaseSideBar,
    BaseSearch,
    BaseButton,
    Categories,
  },
  computed: {
    ...mapGetters({
      cartOrderLinesNumber: "Orders/getCartOrderLinesNumber",
    }),
  },
  methods: {
    async navigateToCategoryPage() {
      if (this.cartOrderLinesNumber > 0)
        await this.$store.dispatch("Orders/clearCart");
      this.$router.push({
        name: routerNames.category,
        params: { category: "הסל שלי" },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@media (min-width: 1024px) {
  .categoreis {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px !important;

    a {
      color: inherit;
    }
  }
  .category {
    font-family: "felix007", sans-serif;
    text-align: center;
    font-size: $largestTextSize !important;
    letter-spacing: 1px;
    padding: 10px;
    text-shadow: 0px 3px 4px black;
    box-shadow: 1px 1px 1px #ffe6ad;
    background-color: $secondaryTextColor;
    border-radius: 20px;
    color: #ffe6ad;
    height: 100px;
    &.active {
      color: $primaryTextColor !important;
      box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.5), 1px 1px 1px #ffe6ad;
    }
  }
  button {
    &:hover {
      cursor: pointer;
    }
  }
}
.side-categoreis {
  min-width: 20vw;
  .side-continer {
    background: #2a2929;
    padding-top: 30px;
    padding-bottom: 30px;
    min-width: 20vw !important;
    padding-left: 20px;
    padding-right: 20px;
    width: 100% !important;
    margin: unset !important;
    margin-bottom: 100px !important;
    box-shadow: 1px 0px 6px 4px rgba(158, 158, 158, 0.368627451);
    border-radius: 0px 0px 20px 20px;
    button {
      height: 55px;
      width: 100%;
    }
  }
  .plus-icon {
    width: 22px;
  }
  h5 {
    color: $primaryTextColor;
  }
  .categoreis {
    display: flex;
    flex-direction: column;
    .category {
      font-size: $mediumTextSize;
      color: white;
    }
  }
  .collection-item {
    .rado {
      float: right;
      box-shadow: inset 0 0 3px #00000054;
      width: 18px;
      height: 18px;
      margin: 3px 3px 3px 10px;
      border-radius: 50px;
      background: white;
      .innera {
        width: 14px;
        height: 14px;
        margin: 2px;
        border-radius: 50px;
      }
    }
    .rado:lang(en) {
      margin-right: 10px;
      float: left;
    }
  }
  .active {
    .innera {
      background: $primaryColor;
    }
  }
  .rado {
    float: right;
    box-shadow: inset 0 0 3px #00000054;
    width: 18px;
    height: 18px;
    margin: 3px 3px 3px 10px;
    border-radius: 50px;
    background: white;
    .innera {
      width: 14px;
      height: 14px;
      margin: 2px;
      border-radius: 50px;
    }
  }
}
</style>
