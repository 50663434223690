<template>
  <div class="waiting-page felix007">
    <h1>טוען את אתר לקוחות...</h1>
    <img style="width: 20%;" src="/images/headerLogo.png" alt="balkan-header-logo" />
  </div>
</template>

<script>
import { routerNames } from "@/router";
export default {
  unmounted() {
    document.getElementById("dolimo_loader").style.display = "none";
  },
  async mounted() {
    // document.getElementById("dolimo_loader").style.display = "flex";
    const passkey = this.$route.query.passkey;
    const phone = this.$route.query.phone;
    const cust = this.$route.query.cust;
    const agent = this.$route.query.agent;
    const orderId = this.$route.query.orderId;

    // get 15 minutes jwt
    try {
      const response = await this.$store.dispatch(
        "AuthModule/getOneTimeLogin",
        {
          passkey,
          phone,
          cust,
          agent,
        }
      );
      if (response) {
        if (orderId) {
          this.$router.push({
            name: routerNames.order,
            params: { ord: orderId },
          });
        } else {
          this.$router.push({
            name: routerNames.category,
            params: { category: "הסל שלי" },
          });
        }
      } else throw new Error();
    } catch (error) {
      console.log(error);
      this.$router.push({ path: "/" });
    }
  },
};
</script>

<style lang="scss" scoped>
.waiting-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  font-size: 12px;
  font-weight: 500;
  color: $primaryTextColor;
  text-align: center;
}
</style>
