<template>
  <div>
    <ScrollToTopButton bottomPixels="75px" />
    <div class="background-container">
      <div class="background-overlay">
        <h1 class="category-title felix007">
          <span :style="getCategoryNameDisplay.color">
            <span class="white-text">{{
              getCategoryNameDisplay.firstWord === "הסל"
                ? "כל "
                : $route.query.filter === "הסל שלי"
                ? "הסל שלי -  "
                : ""
            }}</span>
            {{ getCategoryNameDisplay.firstWord }}</span
          >
          {{ getCategoryNameDisplay.word }}
        </h1>
      </div>
    </div>
    <section class="main-contant">
      <BaseSearch v-if="!isDesktop"></BaseSearch>
      <CategoryActions />

      <div class="padding-botton-100" v-if="categorizedProductList.length > 0">
        <div
          :class="{ 'products-continer': isDesktop }"
          v-for="categoryGroup in categorizedProductList"
          :key="categoryGroup.category"
        >
          <h5 class="category-label">{{ categoryGroup.category }}</h5>
          <PrdouctItem
            v-for="(product, index) in categoryGroup.products"
            :key="product.part"
            :index="index"
            :product="product"
            :ord="getCart.ord"
            :quant="getProdcutInfoFromCartIfExist(product.part).quant"
            :ordi="getProdcutInfoFromCartIfExist(product.part).ordi"
          />
        </div>
      </div>
      <div
        class="products-continer padding-botton-100"
        v-else-if="filteredProducts.length > 0"
      >
        <PrdouctItem
          v-for="(product, index) in filteredProducts"
          :key="product.part"
          :index="index"
          :product="product"
          :ord="getCart.ord"
          :quant="getProdcutInfoFromCartIfExist(product.part).quant"
          :ordi="getProdcutInfoFromCartIfExist(product.part).ordi"
        />
      </div>

      <NotFound v-else>
        {{ notFoundMessage }}
      </NotFound>
    </section>
  </div>
</template>

<script>
import PrdouctItem from "../helpers/ProductItem.vue";
import CategoryActions from "../helpers/CategoryActions.vue";
import BaseSearch from "../menus/BaseSearch.vue";
import { mapGetters } from "vuex";
import NotFound from "../helpers/NotFound.vue";
import ScrollToTopButton from "../helpers/ScrollToTopButton.vue";

export default {
  components: {
    PrdouctItem,
    CategoryActions,
    NotFound,
    BaseSearch,
    ScrollToTopButton,
  },
  data() {
    return {
      productListLength: 0,
    };
  },
  methods: {
    getProdcutInfoFromCartIfExist(part) {
      const cart = this.$store.getters["Orders/getCart"];
      const orderLine = cart.OrderLines.find(
        (orderLine) => orderLine.part === part
      );
      if (orderLine) {
        return {
          quant: orderLine.quant,
          ordi: orderLine.ordi,
        };
      } else {
        return {
          quant: 0,
          ordi: 0,
        };
      }
    },
    filterProduct(product) {
      let displayProduct = true;
      const categoryParam = this.$route.params.category;
      const filter = this.$route.query.filter;
      if (categoryParam) {
        if (categoryParam === "הסל שלי" || filter === "הסל שלי") {
          if (!(product.CustParts.length > 0 && product.CustParts[0].Star)) {
            displayProduct = false;
          }
        }
        if (categoryParam === "מומלצים") {
          if (!product.isRecommended) displayProduct = false;
        } else if (categoryParam !== "חיפוש" && categoryParam !== "הסל שלי") {
          const productCategory = product.Family.familyname;
          if (productCategory !== categoryParam) displayProduct = false;
        }
      }

      const searchQuery = this.$route.query.search;
      if (searchQuery) {
        const values = Object.values(product);
        const proudctHasValues = values.some((value) => {
          return String(value).includes(searchQuery);
          // return String(value).toLowerCase().includes(searchQuery.toLowerCase());
        });
        if (!proudctHasValues) displayProduct = false;
      }

      return displayProduct; // All tets passed successfully and the product should be displayed
    },
  },
  computed: {
    productsByCategory() {
      return this.filteredProducts.reduce((acc, product) => {
        // If the category doesn't exist in the accumulator, create it
        if (!acc[product.Family.familyname]) {
          acc[product.Family.familyname] = [];
        }
        // Add the product to its category
        acc[product.Family.familyname].push(product);
        return acc;
      }, {});
    },
    categorizedProductList() {
      if (
        this.$route.params.category === "הסל שלי" ||
        this.$route.params.category === "חיפוש"
      ) {
        return Object.entries(this.productsByCategory)
          .map(([category, products]) => ({
            category,
            products: products.sort(
              (a, b) => a.Family.sortOrderNumber - b.Family.sortOrderNumber
            ),
          }))
          .sort((a, b) => {
            // Find the first product in each category to compare sortOrderNumber
            const firstProductA = a.products[0];
            const firstProductB = b.products[0];
            return (
              firstProductA.Family.sortOrderNumber -
              firstProductB.Family.sortOrderNumber
            );
          });
      } else {
        return [];
      }
    },
    notFoundMessage() {
      const category = this.$route.params.category;
      const searchQuery = this.$route.query.search;

      if (category === "הסל שלי" && !searchQuery) {
        return "אין לך מוצרים. ניתן להוסיף מוצרים על ידי לחיצה על הכוכב בכרטיסיית המוצר.";
      } else if (category === "חיפוש") {
        return "לא הצלחנו למצוא את המוצר שחיפשת";
      } else {
        return `לא הצלחנו למצוא המוצר שחיפשת בקטגוריית ${category}`;
      }
    },
    filteredProducts() {
      return this.getProducts.filter((product) => this.filterProduct(product));
    },
    getCategoryNameDisplay() {
      let styleColor = "color: white";
      const words = this.$route.params.category.split(" ");
      if (words.length === 1) styleColor = "color: inherit";
      const firstWordWhiteColor = words.shift();
      return {
        firstWord: firstWordWhiteColor,
        color: styleColor,
        word: words.join(" "),
      };
    },
    ...mapGetters({
      getProducts: "Parts/getParts",
      getCustname: "AuthModule/getCustname",
      getCart: "Orders/getCart",
      isDesktop: "isDesktop",
      isLaptop: "isLaptop",
      isTablet: "isTablet",
      isMobile: "isMobile",
    }),
  },
};
</script>

<style scoped lang="scss">
.category-label {
  color: $primaryColor;
  font-weight: bold;
  padding: 5px 10px;
  font-size: 24px;
  background: linear-gradient(
    60deg,
    rgba(0, 0, 0, 0) 30%,
    rgba(255, 230, 173, 1) 97%
  );
  border-radius: 10px;
}

.background-container {
  background-image: url("/images/background-categories.png");
  background-size: 100%;
  background-position-y: -250px;
  background-repeat: no-repeat;
  width: 100%;
  height: 240px;

  .background-overlay {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: linear-gradient(179deg, #161616 10%, rgba(0, 0, 0, 0.412) 80%);
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 1200px) {
  .background-container {
    height: 170px;
    background-position-y: 0px;
  }
}

@media (max-height: 667px) {
  .background-container {
    height: 140px;
    background-position-y: 0px;
  }
}

@media (min-width: 1400px) {
  .products-continer {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}
@media (min-width: 1024px) {
  .products-continer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 20px !important;
  }
  .img-overlay {
    background: unset !important;
  }
}

.products-continer {
  // height: calc(85vh - 180px);
  // max-height: 450px;
  margin-top: 30px;
  // overflow: scroll;
  // padding-left: 500px;
  // overflow-x: hidden;
  // padding-bottom: 100px;
}

@media (max-width: 1100px) {
  .products-continer {
    padding-left: 0px;
  }

  .scroll {
    overflow-y: scroll;
  }
}

.main-contant {
  width: 100%;
  position: relative;
  z-index: 5;
  padding: $mainContentPadding;
  margin-top: -50px;
  // margin-bottom: 75px;
}
.category-title {
  color: $primaryTextColor;
  font-weight: normal;
  font-size: 2.4rem !important;
  margin-top: 0px;
  width: 100%;
  padding: 0px $mainContentPadding;
  margin-bottom: 40px;

  span {
    color: white;
  }
}

.no-prdocuts-found-container {
  flex-direction: column;
  gap: 10px;
  height: 400px;
  h1 {
    color: $primaryTextColor;
    font-size: $largTextSize;
    text-align: center;
    margin: 0;
  }
}
</style>
