import { vapidPublicKey } from "./config";
import AlertService from "./services/AlertService";

function urlBase64ToUint8Array(base64String) {
  var padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  var base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function configurePushSub() {
  if (!("serviceWorker" in navigator)) {
    return;
  }

  let reg;
  navigator.serviceWorker.ready
    .then(function (sw) {
      reg = sw;
      return sw.pushManager.getSubscription();
    })
    .then(function (subscription) {
      if (subscription === null) {
        // Create a new subscription
        reg.pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(vapidPublicKey),
          })
          .then(function (newSub) {
            // console.log("New subscription: ", newSub);
            // Update the db with the new subscription
            return AlertService.saveSubscriber(newSub);
          })
          .then(function (res) {
            console.log(res);
          })
          .catch(function (err) {
            console.log(err);
          });
      } else {
        // We have a subscription
        return;
      }
    });
}

export default function askForNotificationPermission() {
  const pushNotificationPermission = localStorage.getItem("pushNotification");
  if (pushNotificationPermission === "denied") {
    return;
  }
  Notification.requestPermission(function (result) {
    if (result === "granted") {
      console.log("Notification permission granted.");
      localStorage.setItem("pushNotification", "granted");
      configurePushSub();
    } else if (pushNotificationPermission !== "granted") {
      console.log("Unable to get permission to notify.");
      AlertService.removeSubscriber()
        .then(function (res) {
          if (res.status === 202) {
            localStorage.setItem("pushNotification", "denied");
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  });
}
