<template>
  <div>
    <div v-if="isLaptop || isTablet || isMobile" class="main-contant">
      <section v-if="!getUser.agent" class="alert-continer">
        <div class="alert-backGound">
          <img class="icon" src="/images/info-icon.png" />
          <span class="Heebo">
            עבור מועד האספקה הקרוב יש להזמין עד <b> {{ getLastOrderHour }} </b>
          </span>
        </div>
      </section>
      <section class="delivery flex gap-05">
        <img class="iconWithGrayBg" src="/images/delivery.png" />
        <div>
          <h1 class="felix007">מועד אספקה</h1>
          <div class="flex gap-05">
            <img class="pinter-icon" src="/images/pinter.png" />
            <span class="Heebo">משלוח עבור {{ customerName }}</span>
          </div>
        </div>
      </section>
      <Select
        @select="setSelectedDeliveryOptions"
        :options="deliveryOptions"
        :selectedOptions="selectedDeliveryOptions"
      />
      <section class="products flex gap-10">
        <div class="flex gap-05">
          <img class="iconWithGrayBg" src="/images/cart-icon.png" />
          <h1 class="felix007">מוצרים בהזמנה</h1>
        </div>
        <div class="products-container">
          <transition-group name="slide">
            <ProductItem
              v-for="(line, index) in getCartOrderLines"
              :key="line.ordi"
              :index="index"
              :product="line.Part"
              :ordi="line.ordi"
              :quant="line.quant"
            />
          </transition-group>
          <router-link :to="getCategoryRoute"
            ><h1 class="Gisha center-text add-products">
              להוספת מוצרים נוספים לחץ כאן
            </h1></router-link
          >
        </div>
      </section>

      <section class="note flex gap-20">
        <div class="flex gap-05">
          <img class="iconWithGrayBg" src="/images/notes.png" />
          <h1 class="felix007">הערה לתפעול</h1>
        </div>
        <textarea class="Heebo" v-model="Notes" placeholder="טקסט חופשי..">
        </textarea>
      </section>
      <DeliveryDetails :deliveryDetails="getDeliveryDetails" />
      <section class="payment-methods flex gap-20">
        <div class="flex gap-05">
          <img class="iconWithGrayBg" src="/images/credit-card.png" />
          <h1 class="felix007">שיטת תשלום</h1>
        </div>
      </section>
      <NotFound
        v-if="getCustomerBalances.balance > getCustomerBalances.maxBalance"
        imageIcon="alert-icon.png"
      >
        שים לב! הזמנתך חורגת ממכסת האובליגו שמוגדר ללקוח
      </NotFound>
      <Select
        @select="setSelectedPaymentMethodOptions"
        :options="paymentMethodsOptions"
        :selectedOptions="selectedPaymentMethodOptions"
        :debtBalance="getCustomerDebtBalance"
        :totalPriceWithDebtBalance="getTotalPriceWithDebtBalance"
        :isLoadingBalance="isLoadingBalance"
      />
      <section class="flex-column submit gap-05">
        <div
          v-if="isUserHasCartonsLimit"
          class="felix007 minimun-order-message"
        >
          <span>המינימום להזמנה הוא {{ getMinCartOrder }} קרטונים,</span>
          <span>יש להוסיף עוד {{ leftCartNumberForOrder * -1 }} קרטונים</span>
        </div>
        <BaseButton
          :isDisabled="isLoadingBalance || isUserHasCartonsLimit || isLoading"
          @click="sendOrder"
          class="flex gap-10"
        >
          <span> שליחת ההזמנה </span>
          <img class="icon" src="/images/send-icon.png" />
        </BaseButton>
      </section>
    </div>
    <div class="desktop-checkout" v-else>
      <section class="alert-continer">
        <div v-if="!getUser.agent" class="alert-backGound flex gap-05">
          <img class="icon" src="/images/info-icon.png" />
          <span class="Heebo">
            עבור מועד האספקה הקרוב יש להזמין עד
            <b> {{ getLastOrderHour }} </b>
          </span>
        </div>
      </section>
      <section class="delivery flex gap-05">
        <img class="iconWithGrayBg" src="/images/delivery.png" />
        <div>
          <h1 class="felix007">מועד אספקה</h1>
          <div class="flex gap-05">
            <img class="pinter-icon" src="/images/pinter.png" />
            <span class="Heebo">משלוח עבור {{ customerName }}</span>
          </div>
        </div>
      </section>
      <Select
        @select="setSelectedDeliveryOptions"
        :options="deliveryOptions"
        :selectedOptions="selectedDeliveryOptions"
      />
      <section class="products flex gap-10">
        <div class="flex gap-05">
          <img class="iconWithGrayBg" src="/images/cart-icon.png" />
          <h1 class="felix007">מוצרים בהזמנה</h1>
        </div>
        <div class="products-container">
          <transition-group name="slide">
            <ProductItem
              v-for="(line, index) in getCartOrderLines"
              :key="line.ordi"
              :index="index"
              :product="line.Part"
              :ordi="line.ordi"
              :quant="line.quant"
            />
          </transition-group>
        </div>
      </section>
      <DeliveryDetails :deliveryDetails="getDeliveryDetails" />
      <section class="note flex gap-20">
        <div class="flex gap-05">
          <img class="iconWithGrayBg" src="/images/notes.png" />
          <h1 class="felix007">הערה לתפעול</h1>
        </div>
        <textarea class="Heebo" v-model="Notes" placeholder="טקסט חופשי..">
        </textarea>
      </section>
      <section class="payment-methods flex gap-20">
        <div class="flex gap-05">
          <img class="iconWithGrayBg" src="/images/credit-card.png" />
          <h1 class="felix007">שיטת תשלום</h1>
        </div>
        <NotFound
          v-if="getCustomerBalances.balance > getCustomerBalances.maxBalance"
          imageIcon="alert-icon.png"
        >
          שים לב! הזמנתך חורגת ממכסת האובליגו שמוגדר ללקוח
        </NotFound>
      </section>
      <Select
        @select="setSelectedPaymentMethodOptions"
        :options="paymentMethodsOptions"
        :selectedOptions="selectedPaymentMethodOptions"
        :debtBalance="getCustomerDebtBalance"
        :totalPriceWithDebtBalance="getTotalPriceWithDebtBalance"
        :isLoadingBalance="isLoadingBalance"
      />
      <section class="flex-column submit gap-05">
        <div
          v-if="isUserHasCartonsLimit"
          class="felix007 minimun-order-message"
        >
          <span> המינימום לשליחת הזמנה הוא {{ getMinCartOrder }} קרטונים,</span>
          <span> יש להוסיף עוד {{ leftCartNumberForOrder * -1 }} קרטונים </span>
        </div>
        <BaseButton
          :isDisabled="isLoadingBalance || isUserHasCartonsLimit || isLoading"
          @click="sendOrder"
          class="flex gap-10"
        >
          <span> שליחת ההזמנה </span>
          <img class="icon" src="/images/send-icon.png" />
        </BaseButton>
      </section>
    </div>
    <CartModal
      titleOne="שים לב, מועד האספקה השתנה"
      titleTwo="מועד האספקה החדש הינו"
      :newDate="getSelectedDeliveryDate"
      @exit-modal="closeCartModal"
      v-if="openCartModal"
    />
  </div>
</template>

<script>
import Select from "../helpers/Select.vue";
import ProductItem from "../helpers/ProductItem.vue";
import DeliveryDetails from "../helpers/DeliveryDetails.vue";
import BaseButton from "../helpers/BaseButton.vue";
import CartModal from "../helpers/CartModal.vue";
// import store from "../../store";
import OrderService from "@/services/OrderService";
import { formatDate, getNextDays } from "../../Dates";
import { mapGetters } from "vuex";
import { routerNames } from "@/router";
import NotFound from "../helpers/NotFound.vue";
import moment from "moment";

export default {
  components: {
    Select,
    ProductItem,
    BaseButton,
    DeliveryDetails,
    CartModal,
    NotFound,
  },
  data() {
    return {
      nextDeliveryOption: null,
      openCartModal: false,
      selectedDeliveryOptions: 0,
      deliveryOptions: [],
      products: [],
      Notes: "",
      isLoading: false,
      isLoadingBalance: true,
      selectedPaymentMethodOptions: 0,
      paymentMethodsOptions: [
        { title: "תשלום מיתרת אובליגו", type: "obligo" },
        { title: "תשלום באשראי", type: "credit" },
        { title: "תשלום במעמד אספקת סחורה צ'ק/ מזומן", type: "cash" },
      ],
    };
  },
  async mounted() {
    if (this.getCustomerBalances.maxBalance === 0) {
      this.paymentMethodsOptions = this.paymentMethodsOptions.slice(1);
    } else {
      this.paymentMethodsOptions = [this.paymentMethodsOptions[0]];
    }
    const deliveryOptions = await this.$store.getters[
      "DistLines/getCustDistLines"
    ];
    const nextDeliveryOptionsDays = getNextDays(deliveryOptions);
    this.deliveryOptions = nextDeliveryOptionsDays;
    // get the latest customer balance:
    await this.$store.dispatch("Orders/getCustomerBalancesFromHasahsevet");
    this.isLoadingBalance = false;
    this.dateVadlidation();
    this.minOrderChecking();
  },
  methods: {
    minOrderChecking() {
      // Get all the orders which are created today and not מבוטלת / ממתינה לביטול / ממתינה לקליטה
      const orders = this.$store.getters["Orders/getOrders"].filter((order) => {
        return (
          order.status !== 3 && // ממתינה לביטול
          order.status !== 6 && // מבוטלת
          // order.status !== 5 &&
          moment(order.duedate).format("DD/M/YYYY") ===
            moment().format("DD/M/YYYY")
        );
      });
      const deliveryOptions = this.deliveryOptions;

      // Create a Set to store unique send dates of the filtered orders
      const orderDatesSet = new Set(
        orders.map((order) =>
          formatDate(order.senddate, {
            delimiter: "/",
          })
        )
      );

      // Loop through the delivery options and update isMinOrderEnabled property
      for (let j = 0; j < deliveryOptions.length; j++) {
        const deliveryOption = deliveryOptions[j];
        const isMinOrderEnabled = !orderDatesSet.has(deliveryOption.title);

        // Create a new delivery option object with the updated isMinOrderEnabled property
        const newDelivery = {
          ...deliveryOptions[j],
          isMinOrderEnabled,
        };

        // Update the delivery option in the array
        this.deliveryOptions[j] = newDelivery;
      }
    },
    dateVadlidation() {
      // check if the selected devliberyOptions should be disable
      const selectedDeliveryDate = formatDate(this.deliveryOptions[0].title, {
        delimiter: "/",
        ltrFormat: true,
      });
      const isLastOrderHourPassed =
        this.$store.getters["Setting/isLastOrderHourPassed"](
          selectedDeliveryDate
        );

      if (isLastOrderHourPassed && !this.getUser.agent) {
        const newObj = {
          ...this.deliveryOptions[0],
          disabled: true,
        };
        this.deliveryOptions[0] = newObj;
        if (this.deliveryOptions.length > 1) {
          this.selectedDeliveryOptions = 1;
        } else {
          this.selectedDeliveryOptions = 0;
        }
      }
    },
    setSelectedDeliveryOptions(key) {
      const currentKey = this.selectedDeliveryOptions;
      if (currentKey == key) return;
      this.openCartModal = true;
      this.nextDeliveryOption = key;
    },
    setSelectedPaymentMethodOptions(key) {
      this.selectedPaymentMethodOptions = key;
    },
    async generateLinkPaymment() {
      const response = await this.$store.dispatch(
        "Orders/generateLinkPaymment",
        {
          totalAmount: this.getFixedPrice(this.getTotalPriceWithDebtBalance),
          senddate: formatDate(
            this.deliveryOptions[this.selectedDeliveryOptions].title,
            {
              delimiter: "/",
              ltrFormat: true,
            }
          ),
          notes: this.Notes,
          tax: this.getTax,
        }
      );
      this.updateLastOrderHour(response);
      if (response.url) {
        this.$router.push({
          name: routerNames.paymentPage,
          query: { linkPayment: response.url },
        });
      }
    },
    closeCartModal(userRequest) {
      this.isUserConfirmedChangeDeliveryDate = userRequest.isConfirmed;
      if (userRequest.isConfirmed) {
        this.selectedDeliveryOptions = this.nextDeliveryOption;
      }
      this.openCartModal = false;
    },
    async sendOrder() {
      if (
        this.getTypeMethod === "credit" &&
        this.getFixedPrice(this.getTotalPriceWithDebtBalance) > 0
      ) {
        this.generateLinkPaymment();
        return;
      }
      const cart = this.$store.getters["Orders/getCart"];
      const methodPayment = this.getTypeMethod;
      const orderPayLoad = {
        ordname: cart.ordname,
        senddate: formatDate(
          this.deliveryOptions[this.selectedDeliveryOptions].title,
          {
            delimiter: "/",
            ltrFormat: true,
          }
        ),
        notes: this.Notes,
        orderLines: cart.OrderLines,
        peymentmethode: methodPayment,
        debtBalance:
          methodPayment === "credit" ? this.getCustomerDebtBalance : null,
        tax: this.getTax,
      };
      this.isLoading = true;
      const response = await this.$store.dispatch(
        "Orders/sendOrder",
        orderPayLoad
      );
      if (response) {
        if (response.status === 200) {
          this.$router.push("/thank-you");
        } else {
          this.isLoading = false;
        }

        this.updateLastOrderHour(response);
      } else {
        this.isLoading = false;
      }
    },
    async closeOrderSucceededModal() {
      const ordersa = await OrderService.getOrders({});
      await this.$store.dispatch("Orders/updateOrders", ordersa.data.orders);
      await this.$store.dispatch("Orders/updateCart", ordersa.data.cart);
      this.$router.replace("/");
    },
    updateLastOrderHour(response) {
      if (response.lastOrderHour) {
        this.$store.commit("Setting/setSettingsKeys", {
          lastOrderHour: response.lastOrderHour,
        });
        this.dateVadlidation();
      }
    },
  },
  computed: {
    isUserHasCartonsLimit() {
      return (
        this.leftCartNumberForOrder < 0 &&
        this.deliveryOptions[this.selectedDeliveryOptions]?.isMinOrderEnabled &&
        !this.getUser.agent
      );
    },
    ...mapGetters({
      isDesktop: "isDesktop",
      isLaptop: "isLaptop",
      isTablet: "isTablet",
      isMobile: "isMobile",
      getTotalPriceWithDebtBalance: "Orders/getTotalPriceWithDebtBalance",
      getCustomerDebtBalance: "Orders/getCustomerDebtBalance",
      getCustomerBalances: "Orders/getCustomerBalances",
      getCartTotalPrice: "Orders/getCartTotalPrice",
      getCart: "Orders/getCart",
      customerName: "AuthModule/getCustomerName",
      getTax: "Orders/getTax",
      getCartTotalPriceWithTax: "Orders/getCartTotalPriceWithTax",
      getFixedPrice: "Orders/getFixedPrice",
      getUser: "AuthModule/getUser",
    }),
    getCategoryRoute() {
      return `/${routerNames.category}/חיפוש`;
    },
    getTypeMethod() {
      return this.paymentMethodsOptions[this.selectedPaymentMethodOptions].type;
    },
    getLastOrderHour() {
      const lastOrderHour =
        this.$store.getters["Setting/getSettings"].lastOrderHour;
      return lastOrderHour;
    },
    getMinCartOrder() {
      return this.$store.getters["Setting/getSettings"].minCartOrder;
    },
    leftCartNumberForOrder() {
      const cartTotalCount = this.$store.getters["Orders/getCartTotal"];
      const minCartOrder = this.getMinCartOrder;
      return cartTotalCount - minCartOrder;
    },
    getSelectedDeliveryDate() {
      return this.deliveryOptions[this.nextDeliveryOption].title;
    },
    getCartOrderLines() {
      const cartOrders = this.$store.getters["Orders/getCart"].OrderLines;
      return cartOrders;
    },
    getDeliveryDetails() {
      if (this.$store.getters["Orders/getCartOrderLinesNumber"] === 0)
        this.$router.replace("/");
      const deliveryOptions =
        this.deliveryOptions[this.selectedDeliveryOptions];
      return {
        date: deliveryOptions ? deliveryOptions.title : "",
        tax: this.getTax,
        providedTo: this.customerName,
        products: this.$store.getters["Orders/getCartOrderLinesNumber"],
        cartons: this.$store.getters["Orders/getCartTotal"],
        totalPayment: this.getCartTotalPriceWithTax,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.add-products {
  color: $primaryTextColor;
  font-size: $mediumTextSize;
  margin-top: 20px;
  text-decoration: underline;
  cursor: pointer;
}
@media (min-width: 1024px) {
  .desktop-checkout {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: $mainContentPadding;
    padding-bottom: 60px;
  }
  .alert-continer {
    width: fit-content;
    max-width: 400px;
  }
  .Select {
    width: 80%;
    flex-direction: row;
    align-items: flex-start;
  }
  .products {
    margin-top: 30px !important;
  }
  .note textarea {
    width: 80%;
  }
  .submit {
    width: 80%;
  }
  .delivery-details {
    width: 80%;
  }
  .products-container {
    width: fit-content !important;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}
.delivery-details {
  margin-top: 20px;
}
.products-container {
  width: 100%;
}
.slide-move {
  transition: transform 0.3s ease;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}
.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
.main-contant {
  flex-direction: column;
  padding: $mainContentPadding;
  align-items: flex-start;
  margin-bottom: 75px;
}
h1 {
  font-size: 20px;
  margin: 0px;
  color: #c4c4c4;
  font-weight: 400;
}
.alert-backGound {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
  background: $secondaryColor;
  padding: 5px 10px;
  border-radius: 20px;
  gap: 5px;

  .icon {
    width: 15px;
    height: 15px;
  }
  span {
    color: white;
    font-size: 15px;
  }
}
.delivery {
  justify-content: start;
  margin-bottom: 20px;
  .pinter-icon {
    width: 9px;
    height: 13px;
  }
  span {
    color: $primaryTextColor;
  }
}
.products {
  margin-top: 85px;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 30px;
}
.note {
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  margin-top: 30px;
  textarea {
    font-size: $smallTextSize;
    background: $secondaryColor;
    border-radius: 15px;
    border: none;
    color: white;
    height: 115px;
  }
}
.payment-methods {
  align-items: flex-start;
  flex-direction: column;
  justify-content: start;
  margin-top: 30px;
  margin-bottom: 20px;
}
.submit {
  margin-top: 40px;
  button {
    height: 50px;
    width: 100%;
    .icon {
      width: 23px;
      height: 21px;
    }
  }
}
.minimun-order-message {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 24px;
  color: $primaryTextColor;
  border: 2px solid $primaryTextColor;
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 10px;
  span {
    line-height: 1;
  }
}

@media (max-width: 514px) {
  .minimun-order-message {
    gap: 0px;
  }
}
</style>
