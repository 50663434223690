import PartService from "../../services/PartService";

export default {
  namespaced: true, // all the state, mutations, actions, getters in this module is unique to this store module
  state() {
    return {
      parts: [],
      onlyForCust: false,
      families: [],
    };
  },
  mutations: {
    setParts(state, partsList) {
      let parts = partsList.filter((part) => part.showInPortal);
      parts = parts.sort((a, b) => {
        return a.sortNumber - b.sortNumber;
      });
      state.parts = parts;
    },
    toggleCustOnly(state, mode) {
      state.onlyForCust = mode;
    },
    setFavorite(state, data) {
      let index = state.parts.findIndex((part) => part.part === data.part);
      if (data.action === "insert") {
        state.parts[index].CustParts.push({ Star: data.mode });
      } else {
        state.parts[index].CustParts[0].Star = data.mode;
      }
    },
    setFamilies(state, families) {
      let familiesOrdered = families.slice().sort((a, b) => {
        return a.sortOrderNumber - b.sortOrderNumber;
      });
      state.families = familiesOrdered;
    },
  },
  actions: {
    toggleThis(context, mode) {
      context.commit("toggleCustOnly", mode);
    },
    async setFamilies(context) {
      const families = await PartService.getFamilies();
      context.commit("setFamilies", families.data);
    },
    async updateFamilies(context, familiesObj) {
      context.dispatch("startLoading", null, { root: true });
      try {
        await PartService.updateFamilies(familiesObj);
        context.dispatch(
          "stopLoading",
          {
            status: "success",
            message: "הקטגוריות עודכנו בהצלחה!",
          },
          { root: true }
        );
        context.commit("setFamilies", familiesObj);
      } catch (err) {
        console.log(err);
        context.dispatch(
          "stopLoading",
          {
            status: "error",
            message: err.response.data.message,
          },
          { root: true }
        );
      }
    },
    async toggleFavorite(context, data) {
      // context.dispatch("startLoading", null, { root: true });
      try {
        await PartService.starProduct(data);
        if (!data.mode) {
          setTimeout(() => {
            context.commit("setFavorite", data);
          }, 1000);
        } else context.commit("setFavorite", data);
        return true;
      } catch (err) {
        console.log("error starting product:" + err);
        context.dispatch(
          "stopLoading",
          {
            status: "error",
            message: "ארעה שגיאה, מומלץ לרענן את הדף ולנסות שוב",
          },
          { root: true }
        );
        return false;
      }
    },
  },
  getters: {
    getFamilies(state) {
      return state.families;
    },
    getExistingPartsFamily: (state) => (mode) => {
      if (mode === "הסל שלי") {
        // Create a Set of starred part family names first
        const starredFamilyNames = new Set(
          state.parts
            .filter(part => part.CustParts.length > 0 && part.CustParts[0].Star)
            .map(part => part.Family.familyname)
        );
    
        // Filter families based on the set of starred family names
        return state.families.filter(family => 
          starredFamilyNames.has(family.familyname)
        );
      }
    
      // For other modes, create a Set of part family names
      const partFamilyNames = new Set(
        state.parts.map(part => part.Family.familyname)
      );
    
      // Filter families 
      return state.families.filter(family => 
        family.familyname === "הסל שלי" || 
        family.familyname === "מומלצים" || 
        partFamilyNames.has(family.familyname)
      );
    },
    getPartLengths: (state) => (partId) => {
      //console.log('part iddddd: ', partId);
      console.log(partId);
      return state.onlyForCust;
    },
    getParts(state) {
      return state.parts;
    },
    getonlyForCust(state) {
      return state.onlyForCust;
    },
    getCategories(state) {
      let Categories = {};

      state.parts.forEach((part) => {
        if (part.Family) {
          if (typeof Categories[part.familyname] === "undefined") {
            Categories[part.familyname] = part.Family;
            Categories[part.familyname].counter = 0;
            Categories[part.familyname].custCounter = 0;
          }
          Categories[part.familyname].counter++;
          if (part.CustParts.length > 0)
            Categories[part.familyname].custCounter++;
        }
      });
      return Categories;
    },
  },
};
