<template>
  <div class="home-page">
    <section v-if="isTablet || isMobile" class="main-contant flex gap-20">
      <section class="button-continer-one flex">
        <BaseButton class="new-order-btn" @click="navigateToCategoryPage">
          <div class="flex gap-05">
            <img class="plus-icon" src="/images/plusSmall.svg" />
            <span>{{ $t("home.newOrder") }}</span>
          </div>
        </BaseButton>
      </section>
      <section class="button-continer flex gap-10">
        <BaseButton
          v-if="isHomePage && !isDesktop && !getUser?.isRedirectedFromAgent"
          bgColor="#393945"
          @click="navigateToOrdersPage"
          class="open-orders-btn"
        >
          {{ $t("Header.openOrdersOne") }} <span>{{ openOrders }}</span>
          {{ $t("Header.openOrdersTwo") }}
        </BaseButton>
        <BaseButton
          v-if="!getUser?.isRedirectedFromAgent"
          @click="navigateToOrdersPage"
          :bgColor="'#393945'"
        >
          <div>
            <div class="flex gap-05">
              <img class="icon" src="/images/noteIcon.png" />
              <span>{{ $t("home.viewOrders") }}</span>
            </div>
          </div>
        </BaseButton>
      </section>
      <Massages :messageIcon="this.lastAlert?.icon" @allMassages="allMassages">
        {{ this.lastAlert?.content }}
      </Massages>
      <Balance
        v-if="getCustomerBalances.maxBalance > 0"
        :maxBalancePercent="90"
        :balanceSpended="getCustomerBalances.balance"
        :maxBalance="getCustomerBalances.maxBalance"
      />
      <Carousel
        v-if="getPromotions.length > 0"
        @setCurrent="setCurrent"
        :current="currentslide"
        :slides="getPromotions"
      />
      <section class="Carousel-dots-continer">
        <div class="flex gap-05">
          <div
            v-for="(Promotion, key) in getPromotions"
            :style="barStyle(key)"
            class="bar"
            :key="key"
          ></div>
        </div>
      </section>
      <Contacts />
      <section
        v-if="this.$store.getters['AuthModule/isAuthenticated']"
        class="logOut"
      >
        <a
          href=""
          @click="this.$store.dispatch('AuthModule/logout')"
          class="flex gap-05"
        >
          <img class="logout-icon" src="/images/logout-icon.png" />
          <span class="logOut-button"> התנתקות</span>
        </a>
        <router-link v-if="getUserRole?.customerapp" to="/admin-panel">
          <span class="logOut-button">{{ $t("Header.adminSetting") }}</span>
        </router-link>
      </section>
    </section>
    <section class="main-page-desktop" v-else>
      <div class="right-side gap-20">
        <div class="right-side-continer">
          <section v-if="!isDesktop" class="flex gap-20 buttons">
            <BaseButton @click="navigateToCategoryPage">
              <div>
                <div class="flex gap-05">
                  <img class="plus-icon" src="/images/plusSmall.png" />
                  <span>{{ $t("home.newOrder") }}</span>
                </div>
              </div>
            </BaseButton>
            <BaseButton @click="navigateToOrdersPage" :bgColor="'#393945'">
              <div>
                <div class="flex gap-05">
                  <img class="icon" src="/images/noteIcon.png" />
                  <span>{{ $t("home.viewOrders") }}</span>
                </div>
              </div>
            </BaseButton>
          </section>
          <Massages
            class="massages"
            :messageIcon="this.lastAlert?.icon"
            @allMassages="allMassages"
          >
            {{ this.lastAlert?.content }}
          </Massages>
          <Balance
            v-if="getCustomerBalances?.maxBalance > 0"
            :maxBalancePercent="90"
            :balanceSpended="getCustomerBalances.balance"
            :maxBalance="getCustomerBalances.maxBalance"
          />
          <Contacts class="contacts" />
        </div>
      </div>
      <div class="left-side">
        <div class="promotions-continer">
          <CarouselProduct
            :slide="slide"
            v-for="slide in getPromotions"
            :key="slide.promo"
          />
        </div>
      </div>
    </section>
    <AlertModal
      v-if="isAlertModalOpen"
      @exit-modal="closeAlertModal"
      :alerts="getAlerts"
    />
  </div>
</template>

<script>
import BaseButton from "../helpers/BaseButton.vue";
import Carousel from "../helpers/Carousel.vue";
import Massages from "../helpers/Massages.vue";
import Balance from "../helpers/Balance.vue";
import Contacts from "../helpers/Contacts.vue";
import AlertModal from "../helpers/AlertModal.vue";
import CarouselProduct from "../helpers/CarouselProduct.vue";

import { mapGetters } from "vuex";
import { routerNames } from "@/router";
import askForNotificationPermission from "../../push";
export default {
  data() {
    return {
      currentslide: 0,
      isAlertModalOpen: false,
    };
  },
  components: {
    BaseButton,
    Massages,
    Balance,
    Carousel,
    Contacts,
    AlertModal,
    CarouselProduct,
  },
  mounted() {
    console.log(this.getCustomerBalances);
    if (
      "Notification" in window &&
      "serviceWorker" in navigator &&
      this.isMobile
    ) {
      askForNotificationPermission();
    }
  },
  computed: {
    isHomePage() {
      return this.$route.name === "home";
    },
    ...mapGetters({
      openOrders: "Orders/getOpenOrders",
      getAlerts: "Alert/getAlerts",
      lastAlert: "Alert/lastAlert",
      getUserRole: "AuthModule/getUserRole",
      getPromotions: "Alert/getPromotions",
      getUser: "AuthModule/getUser",
      getAgent: "AuthModule/getAgent",
      getCustomerBalances: "Orders/getCustomerBalances",
      isDesktop: "isDesktop",
      isLaptop: "isLaptop",
      isTablet: "isTablet",
      isMobile: "isMobile",
      cartOrderLinesNumber: "Orders/getCartOrderLinesNumber",
    }),
  },
  methods: {
    closeAlertModal() {
      this.isAlertModalOpen = false;
    },
    async navigateToCategoryPage() {
      if (this.cartOrderLinesNumber > 0)
        await this.$store.dispatch("Orders/clearCart");
      this.$router.push({
        name: routerNames.category,
        params: { category: "הסל שלי" },
      });
    },
    navigateToOrdersPage() {
      this.$router.push({
        name: routerNames.orders,
        params: { status: "הכל" },
      });
    },
    barStyle(key) {
      return {
        background: key === this.currentslide ? "#ffe6ad" : "#393945",
      };
    },
    setCurrent(updateCurrent) {
      this.currentslide = updateCurrent;
    },
    allMassages() {
      this.isAlertModalOpen = true;
    },
  },
};
</script>

<style scoped lang="scss">
.new-order-btn {
  width: 100% !important;
  background-color: $primaryTextColor !important;
  color: black !important;
  font-weight: 500 !important;
  font-size: $mainContentPadding;
  box-shadow: 5px 5px 5px #6c1d12 !important;
  div {
    align-items: end !important;
  }
}
.open-orders-btn span {
  color: $primaryTextColor;
  font-weight: bold;
}

.swiper-container {
  z-index: 0 !important;
}
.swiper-slide {
  height: 140px;
}
@media (min-width: 1024px) {
  .main-page-desktop {
    padding: $mainContentPadding;
    display: flex;
    align-items: flex-start;
    padding: 30px 100px;
  }
  .right-side {
    flex-basis: 50%;
    display: flex;
    width: 50%;
    justify-content: space-around;
    flex-direction: column;
    // margin-right: 50px;
    .right-side-continer {
      flex-direction: column;
      display: flex;
      width: 80%;
      gap: 40px;
    }
  }
  .left-side {
    flex-basis: 50%;
    gap: 20px;
    align-items: flex-start;
  }
  .icon {
    height: 20px;
  }
  .buttons {
    justify-content: space-between;
  }
  .contacts {
    justify-content: space-around;
  }
  .promotions-continer {
    width: 450px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
* {
  text-align: center;
}
.Carousel-dots-continer {
  width: 100%;
}
.bar {
  width: 25px;
  height: 5px;
  background: $secondaryColor;
  border-radius: 10px;
}
.main-contant {
  flex-direction: column;
  margin-left: 15px;
  margin-right: 15px;
  align-items: flex-start;
  .plus-icon {
    width: 20px;
    height: 20px;
  }
  .icon {
    width: 10px;
    height: 10px;
  }
  .button-continer-one {
    width: 100%;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
  }
  .button-continer {
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
}
.logOut {
  width: 100%;
  .logOut-button {
    color: $primaryTextColor;
    font-size: $smallTextSize;
  }
  .logout-icon {
    width: 12px;
    height: 12px;
  }
}

h3 {
  font-size: 20px;
  margin: 0;
  margin-top: 100px;
  margin-bottom: 10px;
  font-weight: 600;
}
h2 {
  font-size: $largestTextSize;
  margin: 0;
  color: $primaryColor;
}
.col {
  float: unset;
}
@media (max-width: 601px) {
  h3 {
    margin-top: 30px;
  }
  img {
    height: 200px;
  }
}
</style>
